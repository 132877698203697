import "./Cart.css";
import { BsCart4 } from "react-icons/bs";

export default function Cart () {

    

    return(
        <a className="cart-container" href="/checkout">
            <BsCart4 className="cart-logo"/>
            <span className="cart-status">Cart</span>
        </a>
    );
}