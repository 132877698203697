import "./SignIn.css";
import logo from "../img/ttm-logo.png";
import { React, useState } from "react";
import { Auth } from '@aws-amplify/auth';

export default function SignIn () {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogIn = async () => {
        try {
            const { isSignedIn, nextStep } = await Auth.signIn({ username, password });
        } catch (error) {
            console.log('error signing in', error);
        }
    }

    return(
        <div className="container">
            <a href="/">
            <img className="logo" src={logo} />
            </a>
            <div className="box">
                <h1>Iniciar sesion</h1>
                <form className="user-form">
                    <label>E-mail</label>
                    <input type="email" placeholder="E-mail" onChange={(e) => setUsername(e.target.value)}></input>
                    <label>Password</label>
                    <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}></input>
                    <button type="button" onClick={handleLogIn}>Log In</button>
                </form>
            </div>
        </div>
    );
}