import './App.css';
import { Routes, Route} from 'react-router-dom';
import Home from './components/Home';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import NotFound from './components/NotFound';

function App () {
  return (
    <Routes>
      <Route path="./" element={<Home/>} />
      <Route path="./signup" element={<SignUp/>} />
      <Route path="./signin" element={<SignIn/>} />
      <Route path="*" element={<Home/>} />
    </Routes>
  );
}

export default App;
