import "./SearchBar.css";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useState } from "react";

export default function SearchBar ( ) {

    const [body, setbody] = useState("inicial");

    function prueba() {
        console.log(body)
        setbody("segundo")
        console.log(body)
    }

    function pruebados() {
        prueba().then(console.log("tercero"))
    }

    function runQuery() {
        
        fetch("https://1ltty5w86e.execute-api.eu-west-1.amazonaws.com/test/getproduct").then((res) => res.json()).then((data) => {console.log(data);})
    }

    return(
            <div className="search-bar">
                <input className="search-input" type="search" placeholder="Buscar"></input>
                    <button className="search-submit" onClick={pruebados}>
                        <div className="search-logo-container">
                            <FaMagnifyingGlass className="search-logo"></FaMagnifyingGlass>
                        </div>
                    </button>
            </div>
    );
}