import "./Account.css";
import { Auth } from '@aws-amplify/auth';
import { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa6";

export default function Account () {
const [userData, setUserData] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUserData(user);
      } catch (error) {
        
      }
    }

    fetchUserData();
  }, []); // Empty dependency array to run the effect only once on component mount

  const name = userData?.attributes?.name;

  return (
    <a className="account-container" href="/signin">
        <FaUser className="user-logo"/>
        <span className="account-status">Hello, {name || 'sign in'}</span>
    </a>
  );
}