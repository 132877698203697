import "./SignUp.css";
import logo from "../img/ttm-logo.png";
import { React, useState } from "react";
import { Auth } from '@aws-amplify/auth';

export default function SignUp () {

    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSignUp = async () => {
        try {
            const { isSignUpComplete, nextStep } = await Auth.signUp({ username, password, attributes: { name }});
        } catch (error) {
            console.log('error signing up', error);
        }
    }

    return (
        <div className="container">
            <a href="/">
            <img className="logo" src={logo} />
            </a>
            <div className="box">
                <h1>Crear cuenta</h1>
                <form className="user-form">
                    <label>Name</label>
                    <input className="name-input" type="text" placeholder="Name" onChange={(e) => setName(e.target.value)}></input>
                    <label>E-mail</label>
                    <input type="email" placeholder="E-mail" onChange={(e) => setUsername(e.target.value)}></input>
                    <label>Password</label>
                    <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}></input>
                    <button type="button" onClick={handleSignUp}>Sign Up</button>
                </form>
            </div>
        </div>
    );
}