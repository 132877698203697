import "./Home.css";
import logo from "../img/ttm-logo.png";
import Account from "./Account";
import Cart from "./Cart";
import BannerPubli from "./BannerPubli";
import Catalogue from "./Catalogue";
import SearchBar from "./SearchBar";
import Chat from "./Chat";
import { FaShippingFast } from "react-icons/fa";
import { useState } from "react";
import Product from "./Product";

export default function Home () {

    return(
        <div className="home-page">
            <div className="top-banner">
                <p className="banner-component"><span className="banner-spec"><FaShippingFast className="ship-logo"></FaShippingFast>Free shipping first order </span>And then, from 12,90€ on app or 19,90€ on web</p>
            </div>
            <div className="main-header">
                <a className="brand-logo" href="localhost:3000">
                    <img className="logo" src={logo} />
                </a>
                <SearchBar className="search-bar"/>
                <div className="control-panel">
                    <Account className="account-logo"/>
                    <Cart className="cart-logo"/>
                </div>
            </div>
            <Catalogue />
            <Chat />
        </div>
    );
}

