import { useState } from "react";
import "./Chat.css";
//import { IoSend } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { RiCustomerService2Line } from "react-icons/ri";

export default function Chat() {

    const socket = new WebSocket('ws:/localhost:3009')
    const form = document.getElementById("chatform")
    
    console.log(form)

    const [isOpen, setIsOpen] = useState(false)

    const handleVisibility = () => {
        setIsOpen(!isOpen);
    }
    
    return(
        <div className="chat">
            { isOpen &&  
            <div className="chat-container">
                <div className="chat-status">
                    
                </div>
                <div className="chat-history">
                    {
                        
                    }
                </div>
                <form className="chat-form" id="chatform">
                    <input type="text" placeholder="Type a message" autocomplete="off"></input>
                    <button type="submit">Enviar</button>
                </form>
            </div> }
            <button className="chat-launcher" onClick={handleVisibility}>
                <div className="launcher-logo">
                    { !isOpen && <div className="assistant-logo">
                         <RiCustomerService2Line className="assistant" />
                    </div>
                    }
                    { isOpen && <div className="close-logo">
                        <IoClose className="close"/>
                        </div>}
                </div>
            </button>
        </div>
        
    );
}