import "./Catalogue.css";
import Product from "./Product.jsx";

export default function Catalogue (products) {
    


    return(
        <main className="catalogue-container">
            <div className="search-info">

            </div>
            <div className="sort-menu">
                <p>Categoria</p>
            </div>
            <div className="product-list">
                
            </div>
        </main>
    );
}